import { Fragment } from 'react';

import useAcknowledgements from './UseAcknowledgements';

import AcknowledgeExistingMemberInfo from './existingMemberInfo/AcknowledgeExistingMemberInfo';
import MemberContactsWrite from '../memberContacts/MemberContactsWrite';
import MemberContactDetail from '../memberContacts/MemberContactDetail';
import MemberContactAddressDetail from '../memberContacts/MemberContactAddressDetail';
import MemberContactPhoneDetail from '../memberContacts/MemberContactPhoneDetail';

import Headings from '../headings/Headings';
import PrimaryButton from '../buttons/PrimaryButton';
import LoadingModal from '../dialogs/LoadingModal';
import PopUpModal from '../dialogs/PopUpModal';
import SecondaryButton from '../buttons/SecondaryButton';
import YesNoSwitch from '../yesNoSwitch/YesNoSwitch';
import Input from '../inputs/Input';
import Acknowledgement from './Acknowledgement';

import Constants from '../../utils/Constants';

import global from '../GlobalStyle.module.css';

const Acknowledgements = ({ memberId, addANewFamilyMemberView }) => {
  const {
    personAcknowledgementInfoPageViewState,
    setPersonAcknowledgementInfoPageViewState,
    basePersonState,
    personSubscriptionsState,
    isLoading,
    arrayData,
    isReadyToSign,
    showModal,
    signature,
    signatureErrorMessage,
    splashOptIn,
    splashOptInErrorMessage,
    message,
    onItemClick,
    onSubmit,
    onSubscriptionOptInContinueClicked,
    onSplashOptInChanged,
    onSignatureChanged,
    onSigned,
    onModalCanceled,
    onContinueClicked
  } = useAcknowledgements(memberId, addANewFamilyMemberView);

  return (
    <Fragment>
      {personAcknowledgementInfoPageViewState?.showConfirmContactInfoView === true &&
        <Fragment>
          <div className="row">
            <div className="col-xs-12">
              <AcknowledgeExistingMemberInfo />
            </div>
          </div>
          <PopUpModal
            widthPct={50}
            maxWidth={250}
            title={Constants.LOADING_MSG}
            displayPopUp={basePersonState.isObjLoading} />
        </Fragment>}
      {personAcknowledgementInfoPageViewState?.showChangeContactInfoView === true &&
        <Fragment>
          <div className="row">
            <div className="col-xs-12">
              <MemberContactsWrite
                acknowledgementsView={true}
                personAcknowledgementInfoPageViewState={personAcknowledgementInfoPageViewState}
                setPersonAcknowledgementInfoPageViewState={setPersonAcknowledgementInfoPageViewState} />
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={(e) => onContinueClicked(e)}>Continue</PrimaryButton>
            </div>
          </div>
        </Fragment>}
      {personAcknowledgementInfoPageViewState?.showChangeContactInfoContactDetailView === true &&
        <div className="row">
          <div className="col-xs-12">
            <MemberContactDetail
              acknowledgementsView={true}
              personAcknowledgementInfoPageViewState={personAcknowledgementInfoPageViewState}
              setPersonAcknowledgementInfoPageViewState={setPersonAcknowledgementInfoPageViewState} />
          </div>
        </div>}
      {personAcknowledgementInfoPageViewState?.showChangeContactInfoContactDetailAddressView === true &&
        <div className="row">
          <div className="col-xs-12">
            <MemberContactAddressDetail
              acknowledgementsView={true}
              personAcknowledgementInfoPageViewState={personAcknowledgementInfoPageViewState}
              setPersonAcknowledgementInfoPageViewState={setPersonAcknowledgementInfoPageViewState} />
          </div>
        </div>}
      {personAcknowledgementInfoPageViewState?.showChangeContactInfoContactDetailPhoneView === true &&
        <div className="row">
          <div className="col-xs-12">
            <MemberContactPhoneDetail
              acknowledgementsView={true}
              personAcknowledgementInfoPageViewState={personAcknowledgementInfoPageViewState}
              setPersonAcknowledgementInfoPageViewState={setPersonAcknowledgementInfoPageViewState} />
          </div>
        </div>}
      {personAcknowledgementInfoPageViewState?.showSubscriptionOptInView === true &&
        <Fragment>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <Headings.H4>Splash Magazine</Headings.H4>
            </div>
              <div className="col-xs-12 usas-extra-top-margin"><b>Splash Magazine (free to all members)</b> is the official publication of USA Swimming, dedicated to inspiring and educating the swimming community. 
              Whether you&apos;re an athlete, coach, or fan, Splash dives into the world of swimming with exclusive stories about elite swimmers, insights into cutting-edge training programs, and educational resources to elevate your knowledge of the sport. 
              Each issue features health and wellness tips, expert advice, and a celebration of swimming achievements at all levels, from grassroots to the Olympic stage. 
              Splash Magazine is your go-to source for staying connected with the sport and its vibrant community.</div>
            <div className="col-xs-12 usas-extra-top-margin">
              <YesNoSwitch
                label={'Receive a free subscription to Splash Magazine?'}
                name={'splashOptIn'}
                checked={splashOptIn}
                error={splashOptInErrorMessage}
                message={splashOptInErrorMessage}
                onChange={onSplashOptInChanged} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSubscriptionOptInContinueClicked}>Continue</PrimaryButton>&nbsp;
            </div>
          </div>
        </Fragment>}
      {personAcknowledgementInfoPageViewState?.showAcknowledgeSwitchesView === true &&
        <Fragment>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              {isLoading === true
                ? <LoadingModal />
                : arrayData.map((acknowledgement, i) => <Acknowledgement key={i} acknowledgement={acknowledgement} onItemClick={onItemClick} />)}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {message && <p className={global.ErrorMessage}>{message}</p>}&nbsp;
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <PrimaryButton type="button" isDisabled={isReadyToSign !== true} onClick={onSubmit}>Continue</PrimaryButton>
            </div>
          </div>
          <PopUpModal
            widthPct={90}
            maxWidth={720}
            title="Signature"
            displayPopUp={showModal === true}
            onModalCanceled={onModalCanceled}>
            <div className="row">
              <div className="col-xs-12">
                <p>Please type your name below to indicate your signature.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <Input
                  error={signatureErrorMessage}
                  label="Signature"
                  message={signatureErrorMessage}
                  name="signature"
                  value={signature}
                  onChange={onSignatureChanged} />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={onSigned}>Sign the documents</PrimaryButton>&nbsp;&nbsp;
                <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
              </div>
            </div>
          </PopUpModal>
        </Fragment>}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personSubscriptionsState.isSaving || personSubscriptionsState.isObjLoading} />
    </Fragment >
  );
};

export default Acknowledgements;